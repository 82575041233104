import React, { useEffect, useState } from 'react';

import { history, routes } from 'router';

import api from 'api';

import { Col, Container, Form, InputGroup, Row, Alert } from 'react-bootstrap';
import { Button } from 'components/simple';
import { Link } from 'react-router-dom';
import UploadDropzone from './UploadDropzone/UploadDropzone';

import styles from './upload.module.scss';

const ExpenseTypes = [
  { value: 'education', title: 'Education' },
  { value: 'sport', title: 'Sport' },
  { value: 'software', title: 'Software' },
  { value: 'other', title: 'Other' },
];
export default function Upload() {
  const {
    data: bankAccounts,
    isLoading: isAccountsLoading,
    isFetching: isAccountsFetching,
    error: errorAccountsFetch,
  } = api.endpoints.fetchAccounts.useQuery();

  const [
    createInvoice,
    { isLoading: isCreateInvoiceLoading, error: errorCreateInvoice, isSuccess: isCreateInvoiceSuccess },
  ] = api.endpoints.createInvoice.useMutation();

  const [state, setState] = useState({
    number: '',
    amount: '',
    bank: '',
    file: null,
    notes: '',
    exprenses: [],
  });

  const [file, setFile] = useState(null);
  const [expenses, setExpenses] = useState(null);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleFileDrop = (selectedFile) => {
    if (selectedFile.length) {
      setFile(selectedFile[0]);
    }
  };

  const handleExpenses = (selectedFile) => {
    if (selectedFile) {
      setExpenses(
        selectedFile.map((fileExp) => ({ file: fileExp, type: ExpenseTypes[0].value, amount: 0, description: '' })),
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();

    form.enctype = 'multipart/form-data';

    form.append('number', state.number);
    form.append('amount', state.amount);
    form.append('bank_id', state.bank);
    form.append('file', file);
    form.append('notes', state.notes);

    expenses?.forEach((expense, idx) => {
      form.append(`expenses[${idx}]file`, expense.file);
      form.append(`expenses[${idx}]type`, expense.type);
      form.append(`expenses[${idx}]amount`, expense.amount);
      form.append(`expenses[${idx}]description`, expense.description);
    });

    createInvoice(form);
  };

  const renderAccountsOptions = () =>
    bankAccounts && bankAccounts?.length
      ? bankAccounts.map((account, index) => (
          <option
            // eslint-disable-next-line react/no-array-index-key
            key={`account-item-${index}`}
            value={account.id}
          >{`${account.iban} (${account.currency})`}</option>
        ))
      : null;

  const renderExpenseType = () =>
    ExpenseTypes.map((option) => (
      <option key={option.value} value={option.value}>
        {option.title}
      </option>
    ));

  const handleExpenseInfo = (evt) => {
    const newExpenses = [...expenses];
    newExpenses[evt.currentTarget.getAttribute('data-idx')][evt.currentTarget.name] = evt.currentTarget.value;
    setExpenses(newExpenses);
  };

  useEffect(() => {
    if (state.bank === '' && bankAccounts?.length) {
      const defaultAccount = bankAccounts.find((b) => b.default) || bankAccounts[0];
      setState({ ...state, bank: defaultAccount?.id });
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (isCreateInvoiceSuccess) {
      history.push(routes.invoicesScreen);
    }
  }, [isCreateInvoiceSuccess]);

  return (
    <div>
      <Container fluid className="mt-3">
        <h2>Upload Invoice</h2>

        {errorCreateInvoice && !errorCreateInvoice?.data?.number && !errorCreateInvoice?.data?.file ? (
          <Alert variant="danger">{JSON.stringify(errorCreateInvoice?.data)}</Alert>
        ) : null}

        {errorAccountsFetch ? <Alert variant="danger">Accounts: {errorAccountsFetch?.message}</Alert> : null}

        {!isAccountsLoading && !isAccountsFetching && !bankAccounts?.length ? (
          <Alert className="mt-3" variant="warning">
            Before creating an invoice, you need to have at least{' '}
            <Link className="alert-link" to={routes.accountsScreen}>
              one bank account
            </Link>
            .{' '}
          </Alert>
        ) : null}

        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Form
              className={`text-start ${!bankAccounts?.length ? styles['disabled-form'] : ''}`}
              onSubmit={handleSubmit}
            >
              <Form.Label>Invoice number</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>#</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="number"
                  value={state.number}
                  onChange={handleChange}
                  required
                  isInvalid={errorCreateInvoice?.data?.number}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errorCreateInvoice?.data?.number}</Form.Control.Feedback>
              <Row>
                <Form.Group style={{ maxWidth: '30%' }} as={Col}>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    placeholder="0.00"
                    type="number"
                    min={0}
                    name="amount"
                    value={state.amount}
                    onChange={handleChange}
                    required
                    step="0.01"
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Account</Form.Label>
                  <select className="form-select" name="bank" value={state.bank} onChange={handleChange} required>
                    {renderAccountsOptions()}
                  </select>
                </Form.Group>
              </Row>
              <Form.Group className="mt-3">
                <Form.Label>Invoice file</Form.Label>
                <UploadDropzone onDropFile={handleFileDrop} accept=".pdf" required />
                <Form.Control.Feedback
                  type="invalid"
                  className="mb-3"
                  style={{ display: errorCreateInvoice?.data?.file?.file ? 'block' : 'none' }}
                >
                  {errorCreateInvoice?.data?.file?.file}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Out of $ since last week 😭"
                  name="notes"
                  value={state.notes}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3">
                <Form.Label>Expenses</Form.Label>
                <UploadDropzone
                  multiple
                  onDropFile={handleExpenses}
                  accept={['image/jpeg', 'image/png', 'application/pdf']}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="mb-3"
                  style={{ display: errorCreateInvoice?.data?.file?.file ? 'block' : 'none' }}
                >
                  {errorCreateInvoice?.data?.file?.file}
                </Form.Control.Feedback>
              </Form.Group>
              {expenses?.map((expense, idx) => (
                <Row key={expense.file.path + expense.file.lastModified}>
                  <Form.Label>Expense: {expense.file.path}</Form.Label>
                  <Form.Group as={Col}>
                    <Form.Label>Expense Amount</Form.Label>
                    <Form.Control
                      placeholder="0.00"
                      type="number"
                      min={0}
                      data-idx={idx}
                      name="amount"
                      value={expense.amount}
                      onChange={handleExpenseInfo}
                      required
                      step="0.01"
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Expense Type</Form.Label>
                    <select
                      className="form-select"
                      name="type"
                      data-idx={idx}
                      value={expense.type}
                      onChange={handleExpenseInfo}
                      required
                    >
                      {renderExpenseType()}
                    </select>
                  </Form.Group>
                  <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Expense Note</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      placeholder="I'm learning japanese this is not Netflix"
                      data-idx={idx}
                      name="description"
                      value={expense.description}
                      onChange={handleExpenseInfo}
                    />
                  </Form.Group>
                </Row>
              ))}
              <Button
                className="w-100 mb-3"
                style={{ height: 38 }}
                variant="primary"
                type="submit"
                isLoading={isCreateInvoiceLoading}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
