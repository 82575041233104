import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';

import { rtkQueryErrorLogger } from 'api/error.middleware';

import logger from 'redux-logger';
import { history } from 'router';

import reducer from './reducers';
import sagas from './sagas';
import api from 'api';

import { checkTokenExpirationMiddleware } from './auth.middleware';

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  checkTokenExpirationMiddleware,
  api.middleware,
  rtkQueryErrorLogger,
  sagaMiddleware,
  routeMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const configureRootStore = () => {
  const store = configureStore({
    reducer,
    middleware: middlewares,
  });

  sagaMiddleware.run(sagas);

  return store;
};

export default configureRootStore();
