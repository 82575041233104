/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Form /* Fade */ /* FormFeedback */ } from 'react-bootstrap';
import PropTypes from 'prop-types';

// import Info from "../Info";
// import Button from "../Button";
// import Error from "../Error";

// import SVG from "components/SVG";

import styles from './input.module.scss';

const Input = ({
  containerClass,
  inputClass = '',
  error,
  type = '',
  onChange,
  invalid,
  fieldError,
  onClick,
  onBlur,
  setFocus,
  label,
  labelClass,
  placeholder,
  tooltipTitle,
  tooltipDescription,
  ...inputProps
}) => {
  // const [showLabel, handleLabelAppearance] = useState(!!props.value);
  const [isVisible /* handleVisibleChange */] = useState(false);
  const [isFocused, setIsFocused] = useState(!!placeholder);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!placeholder) {
      setIsFocused(false);
    }
  };

  const handleChange = ({ target: { name, value, files } }) => {
    // handleLabelAppearance(!!value);
    onChange(name, value, files);
  };

  const input = useRef(null);

  useEffect(() => {
    if (setFocus) {
      input.current.focus();
    }
  }, [setFocus]);

  // eslint-disable-next-line no-nested-ternary
  const activeType = type === 'password' ? (isVisible ? 'text' : 'password') : type;

  const { value } = inputProps;

  const stop = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`${styles['input-container']} ${containerClass}`}>
      {/* <Fade in={!!invalid} tag="span" className="invalid-dot" /> */}
      {label && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onClick={stop}
          className={`${styles['label-wrapper']} ${
            (value && value.toString().length) || isFocused ? styles['label-wrapper-active'] : ''
          } ${isFocused ? styles['custom-input-label-focus'] : ''}
          `}
        >
          <Form.Label className={`${styles['input-label']} ${labelClass}`}>{label}</Form.Label>
          {/* {tooltipTitle && (
            // <Info
            //   className={`${styles["info-label"]}`}
            //   title={tooltipTitle}
            //   description={tooltipDescription}
            // />
          )} */}
        </div>
      )}

      <div className={styles['input-wrap']}>
        <Form.Control
          type={activeType}
          // innerRef={input}
          className={`${styles.input} ${inputClass}`}
          // invalid={invalid}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          onChange={handleChange}
          onClick={onClick}
          onBlur={() => {
            handleBlur();
            onBlur();
          }}
          onFocus={handleFocus}
          placeholder={placeholder}
        />
        <Form.Text className={styles.error}>{error}</Form.Text>

        {/* {type === "password" && (
          <div className={styles["password-eye-wrapper"]}>
            <Button
              variant="transparent"
              onClick={() => handleVisibleChange(!isVisible)}
              className={`${styles["password-eye"]} ${
                isVisible ? styles.visible : ""
              }`}
            >
              <SVG.Eye className={`${isVisible ? "d-block" : "d-none"}`} />
              <SVG.EyeStroke
                className={`${isVisible ? "d-none" : "d-block"}`}
              />
            </Button>
          </div>
        )} */}
      </div>
      {/* <Fade in={!!showLabel} tag="span" className={`input-label ${labelClass}`}>
        {label}
      </Fade> */}
      {/* <Error component={FormFeedback} error={fieldError} /> */}
      <div className={styles['custom-input-invalid-feedback']}>{error}</div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  fieldError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})]),
  invalid: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  setFocus: PropTypes.bool,
};

Input.defaultProps = {
  value: '',
  label: '',
  error: '',
  containerClass: '',
  type: 'text',
  labelClass: '',
  inputClass: '',
  fieldError: '',
  invalid: false,
  onClick: () => {},
  onBlur: () => {},
  onChange: () => {},
  setFocus: false,
};

export default Input;
