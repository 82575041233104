import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

import { logout } from 'store/entities/Users/users.service';

import Navbar from 'components/simple/Navbar';
import DashboardContent from './DashboardContent';

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.onstorage = () => {
      if (!window.localStorage.getItem('token')) {
        dispatch(logout());
      }
    };
  }, []);

  return (
    // TODO: Move styles to class
    <>
      <div className="bg-curve app" />
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Navbar />
        <DashboardContent />
      </div>
    </>
  );
}

export default Dashboard;
