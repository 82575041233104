import { all, fork, put, takeEvery } from 'redux-saga/effects';

import usersSlice from './users.reducer';

import { routes, history } from 'router';

// TODO: Implement navigate
// import { navigate } from "helper/history";

import api from 'api';

const {
  actions: { logoutRequest, logoutSuccess },
} = usersSlice;

// Login
function* loginSuccessHandler({ payload }) {
  try {
    const { access_token: token, refresh_token: refreshToken, new_user: isNewUser } = payload;

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);

    if (isNewUser) {
      history.push(routes.guideScreen);
    } else {
      history.push(routes.loginScreen);
    }

    yield put(api.endpoints.fetchUserProfile.initiate());
  } catch (err) {
    history.push(routes.loginScreen);
  }
}

// Logout
function* logoutWorker() {
  try {
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutSuccess());
  }
}

function* logoutSuccessWorker() {
  localStorage.clear();
  yield history.push(routes.loginScreen);
}

function* watchLoginSuccess() {
  yield takeEvery(api.endpoints.login.matchFulfilled, loginSuccessHandler);
}

function* watchLogoutRequest() {
  yield takeEvery(logoutRequest, logoutWorker);
}

function* watchLogoutSuccess() {
  yield takeEvery(logoutSuccess, logoutSuccessWorker);
}

export default function* rootSaga() {
  yield all([fork(watchLoginSuccess), fork(watchLogoutRequest), fork(watchLogoutSuccess)]);
}
