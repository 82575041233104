import React from 'react';
import { Provider } from 'react-redux';

import Router from './router/router';
import GleapIdentifier from 'components/GleapIdentifier';

import store from './store';

import './App.css';

function App() {
  return (
    <Provider store={store}>
      <GleapIdentifier>
        <div className="App">
          <Router />
        </div>
      </GleapIdentifier>
    </Provider>
  );
}

export default App;
