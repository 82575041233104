/* eslint-disable no-console */
import { isRejectedWithValue } from '@reduxjs/toolkit';
// import { toast } from 'your-cool-library'

import usersSlice from 'store/entities/Users/users.reducer';
// import { logout } from "store/entities/Users/users.service";

const {
  actions: { /* refreshRequest , */ logoutRequest },
} = usersSlice;

export const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action) && process.env.NODE_ENV === 'development') {
    console.warn('We got a rejected action!');
    console.log({
      title: 'Async error!',
      status: action?.payload?.status,
      message: action?.payload?.data?.detail,
    });

    if (action?.payload?.status === 401 /* && action?.type !== refreshRequest().type */) {
      next(logoutRequest());
    }
    return next(action);
  }

  return next(action);
};
