import Gleap from 'gleap';
import { useEffect } from 'react';
import api from 'api';
import { useSelector } from 'react-redux';

if (process.env.REACT_APP_GLEAP_KEY) {
  Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
}

export default function GleapIdentifier({ children }) {
  const userDataSelect = api.endpoints.fetchUserProfile.select();
  const { data } = useSelector(userDataSelect);
  const user = data?.length ? data.at(0) : {};

  useEffect(() => {
    if (user?.id) {
      Gleap.identify(
        user.id,
        {
          name: `${user.first_name} ${user.last_name}`,
          email: user.username,
        },
        btoa(user),
      );
    } else {
      Gleap.clearIdentity();
    }
  }, [user?.id]);

  return children;
}
