import React from 'react';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routes } from 'router';

import wavingIcon from 'assets/icons/waving.png';

export default function Guide() {
  return (
    <Container
      style={{
        height: '100%',
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Row className="mt-3">
        <Col md={{ span: 8, offset: 2 }}>
          <h1 className="text-primary fw-bold">
            Welcome to Invoicer! <img src={wavingIcon} style={{ width: 42, height: 42, marginTop: -7 }} alt="waving" />
          </h1>
          <h3 className="text-black-50 fw-lighter" style={{ marginBottom: 60 }}>
            The best solution to get paid on time!
          </h3>
          <Alert className="mt-4" style={{ margin: 'auto' }} variant="primary">
            <div className="mt-3">Follow these simple steps to get started:</div>
            <div className="mb-4">
              <h4 className="mt-4">Step 1:</h4>
              <Link className="alert-link" to={routes.accountsScreen} target="_blank">
                Add your bank account
              </Link>
            </div>
            <div className="mb-4">
              <h4 className="mt-4">Step 2:</h4>
              <Link className="alert-link" to={routes.uploadInvoiceScreen} target="_blank">
                Then create your first Invoice
              </Link>
            </div>
            <div className="mb-4">
              <h4 className="mt-4">Step 3:</h4>
              <Link className="alert-link" to={routes.invoicesScreen} target="_blank">
                On the Invoices screen, you can find all the Invoices you&rsquo;ve created
              </Link>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}
