/* eslint-disable camelcase */
import { API, authorization, contentTypeJson } from 'api';
import { get, post } from 'api/base';

export const signUp = (fields) => post(`${API}/account/auth/register`, JSON.stringify(fields));

export const activate = (fields) => post(`${API}/account/auth/complete-registration`, JSON.stringify(fields));

export const login = (fields) => post(`${API}/account/auth/auth`, JSON.stringify(fields));

export const getUser = () =>
  get(`${API}/clinicians`, {
    ...authorization(),
  });

export const logout = (token) =>
  post(`${API}/account/auth/logout`, JSON.stringify({ token }), {
    ...authorization(),
    ...contentTypeJson(),
  });

export const lostPassword = (fields) => post(`${API}/account/manage-password/send-code`, JSON.stringify(fields));

export const resetPassword = (fields) => post(`${API}/account/manage-password/reset`, JSON.stringify(fields));

export const changePassword = ({ new_password1, uid, token }) =>
  post(`${API}/account/manage-password/change`, JSON.stringify({ password: new_password1, uid, token }));

export const refresh = (fields) => post(`${API}/account/auth/refresh`, JSON.stringify(fields));

export const userEndpoints = {
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password, token }) => ({
        url: `/users/google-auth`,
        method: 'post',
        body: { username, password, token },
        headers: {},
      }),
    }),
    refresh: builder.mutation({
      query: (refreshToken) => ({
        url: '/token/refresh',
        method: 'post',
        body: { refresh: refreshToken },
        headers: { ...authorization() },
      }),
    }),
    fetchUserProfile: builder.query({
      query: () => ({
        url: `/users/`,
        headers: { ...authorization() },
      }),
    }),
    updateUserProfile: builder.mutation({
      query: ({ userId, profile }) => ({
        url: `/users/${userId}`,
        method: 'PATCH',
        body: profile,
        headers: { ...authorization() },
      }),
    }),
  }),
};
