import React, { useEffect, useState } from 'react';

import api from 'api';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { Button, Loader } from 'components/simple';

const fileds = [
  { field: 'first_name', label: 'First name', required: true },
  { field: 'last_name', label: 'Last name', required: true },
  { field: 'username', label: 'Username', disabled: true },
  { field: 'address', label: 'Address' },
  { field: 'phone_number', label: 'Phone number' },
  { field: 'position', label: 'Position', disabled: true },
];

export default function Userprofile() {
  const { data, error, isLoading, isFetching, refetch } = api.endpoints.fetchUserProfile.useQuery();
  const [updateProfile, { isLoading: isUpdateLoading, isSuccess }] = api.endpoints.updateUserProfile.useMutation();

  const [state, setState] = useState(data && data?.length ? { ...data[0] } : {});

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setState(data && data?.length ? { ...data[0] } : {});
  }, [data]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const renderProfileData = () =>
    fileds.map((field, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Form.Group className="text-start mt-3" key={`profile-field-${index}`}>
        <Form.Label>{field.label}:</Form.Label>
        <Form.Control
          type="text"
          disabled={field.disabled}
          name={field.field}
          value={state[field.field] ? state[field.field] : ''}
          onChange={handleChange}
          required={field.required ? field.required : false}
        />
      </Form.Group>
    ));

  const handleSubmit = (e) => {
    e.preventDefault();

    updateProfile({
      userId: state?.id,
      profile: {
        first_name: state?.first_name || '',
        last_name: state?.last_name || '',
        address: state?.address || '',
        phone_number: state?.phone_number || '',
      },
    });
  };

  return (
    <div>
      <Container fluid className="mt-3">
        <h2>Profile</h2>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            {isSuccess ? (
              <Alert className="mt-3" variant="success">
                Your profile has been successfully updated
              </Alert>
            ) : null}
            {isLoading || isFetching ? <Loader className="mt-3" variant="dark" /> : null}
            {error ? <Alert variant="danger">{error?.data?.detail}</Alert> : null}
            <Form onSubmit={handleSubmit}>
              {renderProfileData()}
              <Button
                className="mt-3 mb-3"
                // TODO: Move to class
                style={{ width: 130, height: 38 }}
                variant="primary"
                type="submit"
                isLoading={isUpdateLoading}
              >
                Save changes
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
