/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import api from 'api';

import iban from 'iban';
import { Container, Alert, Table, Form, Modal } from 'react-bootstrap';
import { Button, Loader } from 'components/simple';
import { Link } from 'react-router-dom';

import tableStyles from 'styles/table-styles.module.scss';

const keys = ['iban', 'currency'];

const initialState = {
  showCreateAccountModal: false,
  editingCurrency: '',
  editingIban: '',
  editingAccount: null,
  currency: 'USD',
  iban: '',
};

function Accounts() {
  const [state, setState] = useState({ ...initialState });
  const { data: accounts, isLoading, isFetching, error, refetch } = api.endpoints.fetchAccounts.useQuery();

  const [
    createAccount,
    { isLoading: isAccountCreateLoading, error: errorCreateAccount, isSuccess: isCreateAccountSuccess },
  ] = api.endpoints.createAccount.useMutation();

  const [
    setDefaultAccount,
    { isLoading: isSetDefaultAccountLoading, error: errorSetDefaultAccount, isSuccess: isSetDefaultAccountSuccess },
  ] = api.endpoints.setDefaultAccount.useMutation();

  const [
    updateAccount,
    { isLoading: isUpdateAccountLoading, error: errorUpdateAccount, isSuccess: isUpdateAccountSuccess },
  ] = api.endpoints.updateAccount.useMutation();

  const [
    removeAccount,
    { isLoading: isAccountRemoveLoading, error: errorRemoveAccount, isSuccess: isRemoveAccountSuccess },
  ] = api.endpoints.removeAccount.useMutation();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isCreateAccountSuccess) {
      refetch();
    }
  }, [isCreateAccountSuccess]);

  useEffect(() => {
    if (isRemoveAccountSuccess) {
      refetch();
    }
  }, [isRemoveAccountSuccess]);

  useEffect(() => {
    if (isSetDefaultAccountSuccess) {
      refetch();
    }
  }, [isSetDefaultAccountSuccess]);

  useEffect(() => {
    if (isUpdateAccountSuccess) {
      refetch();
    }
  }, [isUpdateAccountSuccess]);

  useEffect(() => {
    if (isCreateAccountSuccess || isUpdateAccountSuccess) {
      setState({ ...initialState });
    }
  }, [isCreateAccountSuccess, isUpdateAccountSuccess]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const isValidEmail = (value) => /^\S+@\S+\.\S+$/.test(value);

  const isIbanInvalid = (ibanValue) => {
    if (ibanValue.length === 0 || iban.isValid(ibanValue) || isValidEmail(ibanValue) /* check whether it email */) {
      return null;
    }
    return 'The value is not valid IBAN/Email';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isIbanInvalid(state.iban)) {
      createAccount({ account: { iban: state.iban, currency: state.currency } });
    }
  };

  const handleSetDefault = (id) => {
    setDefaultAccount({ accountId: id });
  };

  const handleRemove = (id) => {
    removeAccount({ accountId: id });
  };

  const handleCloseCreateAccountModal = () => {
    setState({ ...state, showCreateAccountModal: false });
  };

  return (
    <div>
      <Container fluid className="mt-3">
        <h2>Accounts</h2>
        <h4>
          Please consider, there is no ability to change bank account information if you already created invoice for it.
        </h4>
        {isFetching ? <Loader className="mt-3 mb-3" variant="dark" /> : null}

        {errorUpdateAccount && !errorUpdateAccount?.data?.iban?.length ? (
          <Alert variant="danger">{errorUpdateAccount?.message || JSON.stringify(errorUpdateAccount?.data)}</Alert>
        ) : null}
        {errorRemoveAccount ? (
          <Alert variant="danger">{errorRemoveAccount?.message || JSON.stringify(errorRemoveAccount?.data)}</Alert>
        ) : null}
        {errorSetDefaultAccount ? <Alert variant="danger">{errorSetDefaultAccount?.message}</Alert> : null}
        <div>
          <Button
            className="mb-3 mt-3"
            variant="outline-primary"
            onClick={() => {
              setState({ ...state, showCreateAccountModal: true });
            }}
          >
            <i className="fas fa-plus" /> Add account
          </Button>
        </div>
        {isLoading ? <Loader /> : null}
        {error ? <Alert variant="danger">{error?.data?.detail}</Alert> : null}
        {!isLoading && accounts?.length ? (
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              if (!isIbanInvalid(state.editingIban)) {
                updateAccount({
                  account: {
                    currency: state.editingCurrency,
                    iban: state.editingIban,
                    id: state.editingAccount,
                  },
                });
              }
            }}
          >
            <Table responsive className="mt-2 mb-3" hover>
              <thead className={tableStyles.head}>
                <tr>
                  <th>#</th>
                  <th scope="col">IBAN or Email</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Default</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {accounts?.length ? (
                  [...accounts]
                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                    .map((account, index) => (
                      <tr key={index}>
                        <td style={{ verticalAlign: 'center' }}>{index}</td>
                        {state.editingAccount !== account?.id ? (
                          keys.map((key, index2) => (
                            <td style={{ verticalAlign: 'center' }} key={`${index}-${index2}`}>
                              {account[key]}
                            </td>
                          ))
                        ) : (
                          <>
                            <td>
                              <Form.Control
                                style={{ width: 300, margin: '0 auto' }}
                                size="sm"
                                name="editingIban"
                                value={state.editingIban}
                                onChange={handleChange}
                                isValid={iban.isValid(state.editingIban) || isValidEmail(state.editingIban)}
                                isInvalid={
                                  isIbanInvalid(state.editingIban)
                                    ? isIbanInvalid(state.editingIban)
                                    : errorUpdateAccount?.data?.iban?.length
                                    ? errorUpdateAccount?.data?.iban[0]
                                    : null
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {isIbanInvalid(state.editingIban)
                                  ? isIbanInvalid(state.editingIban)
                                  : errorUpdateAccount?.data?.iban?.length
                                  ? errorUpdateAccount?.data?.iban[0]
                                  : null}
                              </Form.Control.Feedback>
                            </td>
                            <td>
                              <select
                                className="form-select form-select-sm"
                                style={{ width: 80, textAlign: 'center', margin: '0 auto' }}
                                name="editingCurrency"
                                value={state.editingCurrency}
                                onChange={handleChange}
                              >
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="UAH">UAH</option>
                                <option value="GBP">GBP</option>
                              </select>
                            </td>
                          </>
                        )}
                        <td>
                          {!account.default ? (
                            <Button
                              variant="outline-primary"
                              size="sm"
                              style={{ width: 90, height: 31 }}
                              onClick={() => {
                                handleSetDefault(account?.id);
                              }}
                              isLoading={isSetDefaultAccountLoading}
                            >
                              Set default
                            </Button>
                          ) : (
                            <p className="text-success mt-1 mb-0">
                              <i className="far fa-check-circle" />
                            </p>
                          )}
                        </td>
                        <td className="text-end">
                          {!account?.account_has_invoices ? (
                            account?.id !== state.editingAccount ? (
                              <Button
                                className="ms-2"
                                variant="outline-warning"
                                size="sm"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    editingAccount: account?.id,
                                    editingCurrency: account?.currency,
                                    editingIban: account?.iban,
                                  });
                                }}
                              >
                                <i className="far fa-edit" />
                              </Button>
                            ) : (
                              <>
                                <Button
                                  className="ms-2"
                                  variant="outline-success"
                                  size="sm"
                                  type="submit"
                                  title="Save changes"
                                  isLoading={isUpdateAccountLoading}
                                >
                                  <i className="far fa-save" />
                                </Button>
                                <Button
                                  className="ms-2"
                                  variant="outline-warning"
                                  size="sm"
                                  onClick={() => {
                                    setState({ ...state, editingAccount: null });
                                  }}
                                  title="Cancel editing"
                                >
                                  <i className="fas fa-times-circle" />
                                </Button>
                              </>
                            )
                          ) : null}
                          {!account?.account_has_invoices ? (
                            <Button
                              className="ms-2"
                              variant="outline-danger"
                              size="sm"
                              onClick={() => {
                                // eslint-disable-next-line no-alert, no-restricted-globals
                                if (confirm('Are you sure?')) {
                                  handleRemove(account?.id);
                                }
                              }}
                              isLoading={isAccountRemoveLoading}
                            >
                              <i className="far fa-trash-alt" />
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="8" align="center">
                      <div>No data</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Form>
        ) : null}
        {!isLoading && !isFetching && !accounts?.length ? (
          <div className="alert alert-primary mt-3" role="alert">
            No accounts yet,{' '}
            <Link
              className="alert-link"
              to="/"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, showCreateAccountModal: true });
              }}
            >
              add your first bank account
            </Link>
          </div>
        ) : null}

        <Modal centered show={state.showCreateAccountModal} onHide={handleCloseCreateAccountModal}>
          <Modal.Header className="bg-primary text-white">
            <Modal.Title>Add new account</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="">
              {errorCreateAccount?.message || errorCreateAccount?.data?.non_field_errors ? (
                <Alert variant="danger">
                  {errorCreateAccount?.message || errorCreateAccount?.data?.non_field_errors}
                </Alert>
              ) : null}
              <div className="d-flex justify-content-center align-items-start flex-column flex-md-row">
                <Form.Group controlId="formGridCity">
                  <Form.Label>Iban</Form.Label>
                  <Form.Control
                    style={{ width: 340 }}
                    placeholder="IBAN or Email"
                    name="iban"
                    value={state.iban}
                    onChange={handleChange}
                    required
                    isInvalid={isIbanInvalid(state.iban) || errorCreateAccount?.data?.iban}
                    isValid={iban.isValid(state.iban) || isValidEmail(state.iban)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {isIbanInvalid(state.iban) || errorCreateAccount?.data?.iban}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formGridCity" className="ms-0 ms-md-3 mt-3 mt-md-0">
                  <Form.Label>Currency</Form.Label>
                  <select className="form-select" name="currency" value={state.currency} onChange={handleChange}>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="UAH">UAH</option>
                    <option value="GBP">GBP</option>
                  </select>
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseCreateAccountModal}>
                Cancel
              </Button>
              <Button
                variant="outline-primary"
                className="ms-3"
                style={{ height: 38, width: 70 }}
                type="submit"
                isLoading={isAccountCreateLoading}
              >
                Add
              </Button>
            </Modal.Footer>{' '}
          </Form>
        </Modal>
      </Container>
    </div>
  );
}

export default Accounts;
