import usersSlice from './entities/Users/users.reducer';
import jwtDecode from 'jwt-decode';

import { API } from 'api';

const {
  actions: { refreshRequest, refreshSuccess, refreshFailure, logoutRequest },
} = usersSlice;

const postData = async (url = '', data = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

// eslint-disable-next-line no-unused-vars
const refreshTokenRequest = (dispatch) => {
  const refresh = localStorage.getItem('refreshToken');

  const refreshTokenPromise = postData(
    `${API}/token/refresh`,
    {
      refresh,
    },
    null,
    true,
  )
    .then((resp) => {
      if (resp?.access) {
        localStorage.setItem('token', resp?.access);
        dispatch(refreshSuccess());
      } else {
        dispatch(refreshFailure());
      }

      return resp?.access
        ? Promise.resolve(resp)
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject({
            message: 'could not refresh token',
          });
    })
    .catch(() => {
      dispatch(refreshFailure());
      dispatch(logoutRequest());
    });

  dispatch(refreshRequest(refreshTokenPromise));

  return refreshTokenPromise;
};

export const checkTokenExpirationMiddleware = (/* store */) => (next) => (action) => {
  const token = localStorage.getItem('token');

  try {
    // TODO: Figure out how to refesh token before request
    // if (
    //   token &&
    //   jwtDecode(token).exp < Date.now() / 1000 + 75 &&
    //   action?.type !== refreshRequest().type &&
    //   action?.type !== refreshSuccess().type &&
    //   action?.type !== refreshFailure().type
    // ) {
    //   // const state = store.getState();

    //   // if (!state.users.refreshTokenPromise) {
    //   //   return refreshTokenRequest(store.dispatch, state).then(() => {
    //   //     console.log(action);
    //   //     store.dispatch(action);
    //   //   });
    //   // }

    //   // return state.users.refreshTokenPromise.then(() => store.dispatch(action));
    //   return next();
    // }

    if (token && jwtDecode(token).exp < Date.now() / 1000) {
      localStorage.clear();
      return next(logoutRequest());
    }

    return next(action);
  } catch (err) {
    localStorage.clear();
    return next(logoutRequest());
  }
};
