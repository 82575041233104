/* eslint-disable import/no-unresolved */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { userEndpoints } from 'store/entities/Users/users.service';
import { accountEndpoints } from 'store/entities/Account/account.service';
import { invoiceEndpoints } from 'store/entities/Invoice/invoice.service';

export const API = `${process.env.REACT_APP_API_URL}/api/v1`;

export const authorization = () => ({ Authorization: `Bearer ${localStorage.getItem('token')}` });

export const contentTypeJson = () => ({ 'Content-Type': 'application/json' });

export const invoicerApi = createApi({
  reducerPath: 'invoicerApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/api/v1` }),
  endpoints: () => ({}),
});

const extendedApi = invoicerApi
  .injectEndpoints({ ...userEndpoints }, { overrideExisting: false })
  .injectEndpoints({ ...accountEndpoints }, { overrideExisting: false })
  .injectEndpoints({ ...invoiceEndpoints }, { overrideExisting: false });

export const {
  useFetchUserProfileQuery,
  useFetchInvoicesList,
  useFetchShareAudienceValidateEmailMutation,
  useShareAudienceMutation,
} = extendedApi;

export default extendedApi;
