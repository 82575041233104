import React from 'react';
import { Router, Redirect, Switch } from 'react-router';
import { history } from './history';

// Routes paths
import * as routes from './routes';

// Guard routes
import PrivateRoute from 'components/PrivateRoute';
import UnauthorizedOnlyRoute from 'components/UnauthorizedOnlyRoute';

// Components
import AuthContainer from 'screens/Auth/AuthContainer';
import Dashboard from 'screens/Dashboard';

const MainRouter = () => (
  <Router history={history}>
    <Switch>
      <UnauthorizedOnlyRoute exact path={routes.loginScreen} component={AuthContainer} />

      <PrivateRoute exact path={routes.dashboardScreen} component={Dashboard} />

      <PrivateRoute exact path={routes.uploadInvoiceScreen} component={Dashboard} />

      <PrivateRoute exact path={routes.invoicesScreen} component={Dashboard} />

      <PrivateRoute exact path={`${routes.invoicesScreen}/:id/edit`} component={Dashboard} />

      <PrivateRoute exact path={routes.accountsScreen} component={Dashboard} />

      <PrivateRoute exact path={routes.profileScreen} component={Dashboard} />

      <PrivateRoute exact path={`${routes.accountsScreen}/:id/edit`} component={Dashboard} />

      <PrivateRoute exact path={routes.guideScreen} component={Dashboard} />

      <Redirect from="*" to={routes.dashboardScreen} />
    </Switch>
  </Router>
);

export default MainRouter;
export { history, routes };
