/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Loader from 'components/simple/Loader';

import styles from './upload-dropzone.module.scss';

export default function UploadDropzone({
  multiple = false,
  style,
  onDropFile,
  isLoading,
  required,
  accept,
  placeholder,
}) {
  const [file, setFile] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles);
    onDropFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
    maxSize: '5242880',
  });
  return (
    <div style={style} className={styles['dropzone-container']}>
      <div {...getRootProps()} style={{ position: 'relative' }}>
        <input
          {...getInputProps()}
          style={{
            display: 'input',
            width: 1,
            height: 1,
            position: 'absolute',
            left: '50%',
            top: '70%',
          }}
          required={!file.length && required}
        />
        {!file.length ? (
          <>
            {isLoading ? (
              <Loader />
            ) : isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>
                {placeholder ||
                  `Drag 'n' drop file${multiple ? 's' : ''} here, or click to select file${multiple ? 's' : ''}`}
              </p>
            )}
          </>
        ) : (
          <div style={{ padding: 12 }}>{file?.map((f) => f?.path).join(', ')}</div>
        )}
      </div>
    </div>
  );
}
