import React, { useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import api from 'api';

import { Alert } from 'react-bootstrap';

import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Loader from 'components/simple/Loader';

import authStyles from '../AuthContainer/auth-container.module.scss';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Login = () => {
  const [state, setState] = useState({ googleLoginError: null });

  const [loginRequest, { isLoading }] = api.endpoints.login.useMutation();

  const onSuccessGoogle = (response) => {
    loginRequest({
      token: response.credential,
    });
  };

  const onFailureGoogle = (response) => {
    setState({ ...state, googleLoginError: response?.error });
  };

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <div className={authStyles.loginContainer}>
        {state?.googleLoginError && state?.googleLoginError !== 'popup_closed_by_user' ? (
          <Alert variant="danger">{state.googleLoginError}</Alert>
        ) : null}
        <div className={authStyles.title}>Welcome back</div>
        {isLoading ? (
          <Loader />
        ) : (
          <form className={authStyles.form} onSubmit={(e) => e.preventDefault()}>
            <div className={authStyles.socialContainer}>
              <GoogleLogin hosted_domain="gowombat.team" onSuccess={onSuccessGoogle} onError={onFailureGoogle} />
            </div>
          </form>
        )}
      </div>
    </GoogleOAuthProvider>
  );
};

Login.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

const mapStateToProps = ({ users }) => ({
  isLoading: false,
  isActivateLoading: false,
  activateSuccess: false,
  errorMessage: users.errorMessage,
  errors: users.errors,
});

export default connect(mapStateToProps)(Login);
