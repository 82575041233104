import React from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';

import { dashboardScreen } from 'router/routes';

const UnauthorizedOnlyRoute = ({ component: Component, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      localStorage.getItem('token') ? (
        <Redirect
          to={{
            pathname: dashboardScreen,
            state: { from: props.location },
          }}
        />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      )
    }
  />
);

UnauthorizedOnlyRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.any]).isRequired,
  location: PropTypes.shape({}),
};

UnauthorizedOnlyRoute.defaultProps = {
  location: {},
};

export default UnauthorizedOnlyRoute;
