import { all, fork, put, takeEvery } from 'redux-saga/effects';

import api from 'api';

function* accountCreateSuccessHandler() {
  yield put(api.endpoints.fetchAccounts.initiate({ forceRefetch: true }));
}

function* watchAccountCreateSuccess() {
  yield takeEvery(api.endpoints.createAccount.matchFulfilled, accountCreateSuccessHandler);
}

export default function* rootSaga() {
  yield all([fork(watchAccountCreateSuccess)]);
}
