import { authorization } from 'api';

export const accountEndpoints = {
  endpoints: (builder) => ({
    fetchAccounts: builder.query({
      query: () => ({
        url: `/users/banks`,
        headers: { ...authorization() },
      }),
    }),
    createAccount: builder.mutation({
      query: ({ account }) => ({
        url: '/users/banks',
        method: 'post',
        body: account,
        headers: { ...authorization() },
      }),
    }),
    updateAccount: builder.mutation({
      query: ({ account }) => ({
        url: `users/banks/${account?.id}`,
        method: 'PATCH',
        body: account,
        headers: { ...authorization() },
      }),
    }),
    removeAccount: builder.mutation({
      query: ({ accountId }) => ({
        url: `users/banks/${accountId}`,
        method: 'delete',
        headers: { ...authorization() },
      }),
    }),
    setDefaultAccount: builder.mutation({
      query: ({ accountId }) => ({
        url: `/users/banks/${accountId}/change`,
        method: 'put',
        body: {
          default: true,
        },
        headers: { ...authorization() },
      }),
    }),
  }),
};
