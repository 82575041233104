/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';

import usersSlice from 'store/entities/Users/users.reducer';

import { routes } from 'router';

import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import Logo from '../../../logo.svg';
import './navbar.scss';
import api from 'api';

const {
  actions: { logoutSuccess },
} = usersSlice;

// eslint-disable-next-line react/prop-types
const NavBar = () => {
  const dispatch = useDispatch();

  const isAuthenticated = localStorage.getItem('token');

  const { data } = api.endpoints.fetchUserProfile.useQuery(
    {},
    {
      skip: !isAuthenticated,
    },
  );

  // TODO: Replace with postprocessor
  const userProfile = data && data?.length ? data[0] : {};

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutSuccess());
  };

  return (
    <Navbar bg="transparent" variant="dark" expand="lg" className="pt-5">
      <Container>
        <Navbar.Brand style={{ fontSize: 22 }} as={NavLink} to="/">
          <img src={Logo} alt="logo" />
        </Navbar.Brand>
        {isAuthenticated ? (
          <>
            <Navbar.Toggle className="main-color" aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <ul className="navbar-nav mr-auto me-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to={routes.uploadInvoiceScreen}>
                    Upload
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to={routes.invoicesScreen}>
                    Invoices
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to={routes.accountsScreen}>
                    Accounts
                  </NavLink>
                </li>
              </ul>

              <Nav>
                <NavDropdown title={userProfile?.user} id="collasible-nav-dropdown">
                  <NavDropdown.Item as={NavLink} activeClassName="active" to={routes.profileScreen}>
                    <i className="fas fa-user" />
                    <span style={{ marginLeft: 7 }}>Profile</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} activeClassName="active" to={routes.guideScreen}>
                    <i className="fas fa-tasks" />
                    <span style={{ marginLeft: 7 }}>Guide</span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={NavLink} to="/logout" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt" />
                    <span style={{ marginLeft: 7 }}>Logout</span>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : null}
      </Container>
    </Navbar>
  );
};

export default NavBar;
