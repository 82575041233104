import { authorization } from 'api';

export const invoiceEndpoints = {
  endpoints: (builder) => ({
    fetchInvoices: builder.query({
      query: () => ({
        url: `/invoices/`,
        headers: { ...authorization() },
      }),
    }),
    createInvoice: builder.mutation({
      query: (invoice) => ({
        url: '/invoices/',
        method: 'post',
        body: invoice,
        headers: { ...authorization() },
      }),
    }),
    updateInvoice: builder.mutation({
      query: ({ invoice, invoiceId }) => ({
        url: `/invoices/${invoiceId}`,
        method: 'PATCH',
        body: invoice,
        headers: { ...authorization() },
      }),
    }),
    removeInvoice: builder.mutation({
      query: ({ invoiceId }) => ({
        url: `/invoices/${invoiceId}`,
        method: 'delete',
        headers: { ...authorization() },
      }),
    }),
  }),
};
