/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userProfile: {},
  refreshDelayedAction: undefined,
  isTokenRefreshing: false,
  refreshTokenPromise: null,
};

const reducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    refreshRequest(state, { payload }) {
      const refreshTokenPromise = payload;
      state.isTokenRefreshing = true;
      state.refreshTokenPromise = refreshTokenPromise;
    },
    refreshSuccess(state) {
      state.isTokenRefreshing = false;
      state.refreshTokenPromise = null;
    },
    refreshFailure(state) {
      state.isTokenRefreshing = false;
      state.refreshTokenPromise = null;
    },

    logoutRequest() {},
    logoutSuccess() {},
    logoutFailure() {},
  },
  extraReducers: {},
});

export default reducer;
