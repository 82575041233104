import React from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';

import { loginScreen } from 'router/routes';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      localStorage.getItem('token') ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: loginScreen,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.any]).isRequired,
  location: PropTypes.shape({}),
};

PrivateRoute.defaultProps = {
  location: {},
};

export default PrivateRoute;
