import { combineReducers } from '@reduxjs/toolkit';

// import { logoutSuccess } from "../entities/Users/actions";

import api from 'api';

import users from '../entities/Users/users.reducer';
// import notifications from "../entities/notifications/reducer";
// import loading from "../entities/loading/reducer";

// import error from "../entities/error/reducer";

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  users: users.reducer,

  // notifications,
  // loading,
  // error
});

export default (state, action) => reducers(action.type === '' /* logoutSuccess.type */ ? undefined : state, action);
