import React from 'react';
import { Redirect, Switch } from 'react-router';

// Routing
import * as routes from 'router/routes';

// Screens
import Upload from 'screens/Dashboard/Upload';
import Invoices from 'screens/Dashboard/Invoices';
import Accounts from 'screens/Dashboard/Accounts';
import Profile from 'screens/Dashboard/Userprofile';
import Guide from 'screens/Dashboard/Guide';

// Permissions
import PrivateRoute from 'components/PrivateRoute';
// import { AccessControlRoute, permissions } from "components/permissions";

const DashboardContent = () => (
  <Switch>
    <PrivateRoute exact path={routes.uploadInvoiceScreen} component={Upload} />
    <PrivateRoute exact path={routes.invoicesScreen} component={Invoices} />
    <PrivateRoute exact path={routes.accountsScreen} component={Accounts} />
    <PrivateRoute exact path={routes.profileScreen} component={Profile} />
    <PrivateRoute exact path={routes.guideScreen} component={Guide} />

    <Redirect to={routes.invoicesScreen} />
  </Switch>
);
export default DashboardContent;
