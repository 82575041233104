/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import api from 'api';
import { Container, Alert, Badge, Table, Form } from 'react-bootstrap';
import { Loader, Button } from 'components/simple';
import { routes } from 'router';
import { Link } from 'react-router-dom';

import UploadDropzone from '../Upload/UploadDropzone/UploadDropzone';

import tableStyles from 'styles/table-styles.module.scss';

const initialState = {
  editingInvoice: null,
  editingNumber: '',
  editingAmount: '',
  editingAccount: null,
  editingNotes: '',
  editingFile: null,
};

const formatDate = (date) => new Date(date).toLocaleDateString();

function addDays(date, days) {
  if (!date) {
    return '';
  }
  const currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + days);
  return currentDate.toLocaleDateString();
}

function Invoices() {
  const [state, setState] = useState({ ...initialState });

  const { data: bankAccounts /*  error: errorAccountsFetch */ } = api.endpoints.fetchAccounts.useQuery();

  const {
    data: invoices,
    isLoading,
    isFetching,
    error,
    refetch: refetchInvoices,
  } = api.endpoints.fetchInvoices.useQuery(undefined, { refetchOnMountOrArgChange: true });

  // const [removeInvoice, { isSuccess: isRemoveInvoiceSuccess }] =
  //   api.endpoints.removeInvoice.useMutation();

  const [
    updateInvoice,
    { isLoading: isUpdateInvoiceLoading, isSuccess: isUpdateInvoiceSuccess, error: errorUpdateAccount },
  ] = api.endpoints.updateInvoice.useMutation();

  useEffect(() => {
    refetchInvoices();
  }, []);

  useEffect(() => {
    if (isUpdateInvoiceSuccess) {
      setState({ ...state, editingInvoice: null });
      refetchInvoices();
    }
  }, [isUpdateInvoiceSuccess]);

  // useEffect(() => {
  //   if (isRemoveInvoiceSuccess) {
  //     refetch();
  //   }
  // }, [isRemoveInvoiceSuccess]);

  const formatStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'sent':
        return 'info';
      case 'paid':
        return 'success';
      case 'rejected':
        return 'danger';
      case 'pending':
        return 'warning';
      default:
        return 'primary';
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const renderAccountsOptions = () =>
    bankAccounts && bankAccounts?.length
      ? bankAccounts.map((account, index) => (
          <option
            // eslint-disable-next-line react/no-array-index-key
            key={`account-item-${index}`}
            value={account.id}
          >{`${account.iban} (${account.currency})`}</option>
        ))
      : null;

  const onUpdateSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();

    form.enctype = 'multipart/form-data';

    form.append('number', state.editingNumber);
    form.append('amount', state.editingAmount);
    form.append('bank_id', state.editingAccount);
    if (state.editingFile) {
      form.append('file', state?.editingFile);
    }
    form.append('notes', state.editingNotes?.trim());

    // const invoice = {
    //   number: state.editingNumber,
    //   amount: state.editingAmount,
    //   bank_id: state.editingAccount,
    //   notes: state.editingNotes ? state.editingNotes : ""
    // };

    // if (state.editingFile) {
    //   invoice.file = state.editingFile;
    // }

    updateInvoice({
      invoiceId: state.editingInvoice,
      invoice: form,
    });
  };

  const handleFileDrop = (file) => {
    setState({ ...state, editingFile: file[0] });
  };

  const submitPayment = (e, invoiceId) => {
    e.stopPropagation();
    e.preventDefault();
    const form = new FormData();
    form.append('payment_confirmed', true);
    updateInvoice({
      invoiceId,
      invoice: form,
    });
  };

  return (
    <div>
      <Container fluid className="mt-3">
        <h2>Invoices</h2>
        {isFetching ? <Loader className="mt-3" variant="dark" /> : null}
        {!isLoading && error?.data?.detail ? (
          <Alert variant="danger">{error?.data?.detail ? error?.data?.detail : null}</Alert>
        ) : null}

        {errorUpdateAccount && !errorUpdateAccount?.data?.file ? (
          <Alert variant="danger">
            {errorUpdateAccount?.data?.detail
              ? errorUpdateAccount?.data?.detail
              : !errorUpdateAccount?.data?.file && JSON.stringify(errorUpdateAccount?.data)}
          </Alert>
        ) : null}

        {!isLoading && invoices?.length ? (
          <Form onSubmit={onUpdateSubmit}>
            <Table className="mt-2 mb-3" responsive hover>
              <thead className={tableStyles.head}>
                <tr>
                  <th>#</th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Notes</th>
                  <th scope="col">Expenses</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {invoices?.length ? (
                  invoices
                    .map((i) => i)
                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                    .map((invoice, index) => (
                      <tr key={index}>
                        {state.editingInvoice !== invoice?.id ? (
                          <>
                            <td style={{ verticalAlign: 'center' }}>{invoice.number}</td>
                            <td>
                              <Badge className={`bg-${formatStatus(invoice.status)}`}>{invoice.status}</Badge>
                            </td>
                            <td style={{ verticalAlign: 'center' }} className="gleap-hidden">
                              {invoice.amount} {invoice.bank.currency}
                            </td>
                            <td style={{ verticalAlign: 'center' }} className="gleap-hidden">
                              {formatDate(invoice.date)}
                            </td>
                            <td>{invoice?.due_date || addDays(invoice?.date, 14)}</td>
                            <td style={{ verticalAlign: 'center' }} className="gleap-hidden">
                              {invoice.notes}
                            </td>
                            <td>
                              {invoice?.expenses ? (
                                <ul style={{ margin: 0 }}>
                                  {invoice?.expenses.map((expense) => {
                                    const [fileName = '-', ...rest] = expense?.file?.split('/')?.reverse();
                                    return (
                                      <li style={{ listStyle: 'none' }} key={expense.file}>
                                        {fileName} ({expense.type})
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                '-'
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td style={{ verticalAlign: 'center' }}>
                              <Form.Control
                                name="editingNumber"
                                value={state.editingNumber}
                                onChange={handleChange}
                                size="sm"
                                required
                              />
                            </td>
                            <td>
                              <Badge className={`bg-${formatStatus(invoice.status)}`}>{invoice.status}</Badge>
                            </td>
                            <td style={{ verticalAlign: 'center' }}>
                              <Form.Control
                                gleap-ignore="true"
                                name="editingAmount"
                                type="number"
                                placeholder="0.00"
                                min={0.01}
                                value={state.editingAmount}
                                onChange={handleChange}
                                size="sm"
                                required
                                step="0.01"
                              />
                              <select
                                className="form-select form-select-sm mt-3"
                                name="editingAccount"
                                value={state.editingAccount}
                                onChange={handleChange}
                                required
                              >
                                {renderAccountsOptions()}
                              </select>
                            </td>
                            <td style={{ verticalAlign: 'center', whiteSpace: 'nowrap' }}>
                              {formatDate(invoice.date)}
                            </td>
                            <td>{invoice?.due_date || addDays(invoice?.date, 14)}</td>
                            <td style={{ verticalAlign: 'center' }}>
                              <Form.Control
                                as="textarea"
                                style={{ height: 79 }}
                                name="editingNotes"
                                value={state.editingNotes}
                                onChange={handleChange}
                                size="sm"
                              />
                            </td>
                            <td>
                              {invoice?.expenses ? (
                                <ul style={{ margin: 0 }}>
                                  {invoice?.expenses.map((expense) => {
                                    const [fileName = '-', ...rest] = expense?.file?.split('/')?.reverse();
                                    return (
                                      <li style={{ listStyle: 'none' }} key={expense.file}>
                                        {fileName} ({expense.type})
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                '-'
                              )}
                            </td>
                          </>
                        )}
                        <td className="text-end" style={{ whiteSpace: 'nowrap' }}>
                          {state.editingInvoice !== invoice?.id ? (
                            <>
                              <Link
                                className="btn btn-outline-primary btn-sm"
                                to={`api/v1/invoices/download?id=${invoice?.id}`}
                                onClick={(e) => {
                                  e.preventDefault();

                                  const headers = {
                                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                                  };

                                  fetch(`${process.env.REACT_APP_API_URL}/api/v1/invoices/download?id=${invoice?.id}`, {
                                    headers,
                                  })
                                    .then((response) => {
                                      if (!response.ok) {
                                        throw new Error(`${response.status} ${response.statusText}`);
                                      }
                                      return response.blob();
                                    })
                                    .then((blobby) => {
                                      const objectUrl = window.URL.createObjectURL(blobby);

                                      const anchor = document.createElement('a');
                                      document.body.appendChild(anchor);

                                      anchor.href = objectUrl;
                                      anchor.download = `invoice-${invoice?.id}.pdf`;
                                      anchor.click();

                                      window.URL.revokeObjectURL(objectUrl);

                                      anchor.remove();
                                    })
                                    .catch((err) => {
                                      // eslint-disable-next-line no-alert
                                      alert(`${err}`);
                                    });
                                }}
                              >
                                <i className="fas fa-download" />
                              </Link>
                              {invoice?.status?.toLowerCase() === 'pending' ? (
                                <Button
                                  id={`edit-${invoice?.id}`}
                                  className="ms-2"
                                  variant="outline-warning"
                                  size="sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    setState({
                                      ...state,
                                      editingInvoice: invoice?.id,
                                      editingNumber: invoice?.number,
                                      editingAmount: invoice?.amount,
                                      editingNotes: invoice?.notes || '',
                                      editingAccount: invoice?.bank?.id,
                                    });
                                  }}
                                >
                                  <i className="far fa-edit" />
                                </Button>
                              ) : null}
                              {invoice?.status?.toLowerCase() === 'paid' && !invoice.payment_confirmed ? (
                                <Button
                                  id={`edit-${invoice?.id}`}
                                  className="ms-2"
                                  variant="outline-success"
                                  size="sm"
                                  onClick={(e) => submitPayment(e, invoice?.id)}
                                >
                                  Payment received
                                </Button>
                              ) : null}
                            </>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}
                            >
                              <UploadDropzone
                                style={{ height: 49, width: '100%', margin: 0, marginBottom: '10px' }}
                                onDropFile={handleFileDrop}
                                accept=".pdf"
                                placeholder={"Drag 'n' drop file here, or click to choose and replace current file"}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                style={{
                                  display: errorUpdateAccount?.data?.file ? 'block' : 'none',
                                }}
                              >
                                {errorUpdateAccount?.data?.file?.file}
                              </Form.Control.Feedback>
                              <Button
                                className="ms-2"
                                variant="outline-success"
                                size="sm"
                                type="submit"
                                title="Save changes"
                                isLoading={isUpdateInvoiceLoading}
                              >
                                <i className="far fa-save" />
                              </Button>
                              <Button
                                className="ms-2"
                                variant="outline-warning"
                                size="sm"
                                onClick={() => {
                                  setState({ ...state, editingInvoice: null });
                                }}
                                title="Cancel editing"
                              >
                                <i className="fas fa-times-circle" />
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="8" align="center">
                      <div>No data</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Form>
        ) : null}

        {!isLoading && !isFetching && !invoices?.length ? (
          <div className="alert alert-primary mt-3" role="alert">
            No invoices yet,{' '}
            <Link className="alert-link" to={routes.uploadInvoiceScreen}>
              create your first invoice
            </Link>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default Invoices;
